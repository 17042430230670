@import 'variables';
@import 'colors';

*, *:before, *:after {
    box-sizing: inherit;
}
html {
    box-sizing: border-box;
    background: transparent;
}
html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;    
}
h1 {
    font-size: 26px;
}
h3, .card-title {
    font-size: 22px;
}
.card-title {
    font-weight: 600;
}
header,
footer{
    padding:1rem 0;
}
header {
    img {
        max-width: 150px;
    }
    .navbar-nav{
        li {
            font-size: 18px;
        }
    }
}
.login-content {
    border-radius: 3px;
    box-shadow: 0px 4px 12px rgba(101, 107, 118, 0.2)
}
// FORM RESREVATION
.summary-content {
    padding: 1rem;
    margin-top: 1rem;
    h3 {
        font-size: 28px;
        font-weight: bold;
    }
    span {
        font-weight: bold;
    }
}
label {
    font-size: 18px;
}

.summary-page {
    .data-information {
        padding: 24px;
        padding-top: 12px;
        margin-bottom: 24px;
        border-radius: 5px;
        h5 {
            margin: 24px 0;
        }
    }
}
.modal-body {
    .data-information {
        padding: 0;
        padding-top: 0;
        margin-bottom:0;
        border-radius: 0;
        h5 {
            margin:20px 0;
        }
    }
    .reservations-policies {
        height: 300px;
        overflow-y: scroll;
    }
}
//overlay disable for form step 1
.booking-step1 {
    position: relative;
    .disable:before {
        content: '';
        display: block;
        position: absolute;
        left:0; right: 0; bottom: 0; top: 0;
        z-index: 1;
    }
    button.disable {
        display: none;
    }
}
.input-range {
    font-size: 12px;
    height: 38px;
}
.button-excell {
    margin-left: 1.5rem;
}
.textarea-observations {
    width: 100%;
}
footer {
    font-size: .7rem;
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }
}
.logout_link{
    cursor: pointer;
}
th, th.sortable {
    color: $gray;
    font-weight: 500;
    font-size: 12px;
}
td {
    font-size: 14px;
}
.as-react-table {
    .input-group {
        align-items: center;
    }
    .asrt-page-length {
        .input-group-addon {
            background: transparent;
        }
    }
}
.modal-edit {
    label {
        font-size: 14px;
        font-weight: 500;
    }
}
.button-actions {
    button {
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .btn-warning {
        svg {
            color: black;
        }
    }
}
.react-datepicker-wrapper {
    width:100%
}