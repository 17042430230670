/*  "COLORS" STYLES */

body {
    color: $accent-color-3;
    background: #f3f3f3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $accent-color-1;
}
input {
    color: $accent-color-1;
}
a {
    color: $accent-color-1;
}
a.btn span {
    color: white;
}
a:hover,
a:active,
a:focus {
    color: $accent-color-2;
}
.bg-grey{
    background-color: #f3f3f3;
}
header {
    background-color: #ffffff;
    .logout_link {
        
        &:hover {
            color: $accent-color-2;
            
        }
    }
}
.navbar-nav{
    li {
        a {
            color: $accent-color-1 !important;
            &:hover {
                color: $accent-color-2 !important;
            }
        }
    }
}
.Card {
    .card-title {
        color: $accent-color-1;
    }
    
}
.btn-block+.btn-block {
    margin-top: 0 !important;
}
.main-content {
    background-color: #f2f2f2;
    span {
        font-weight: bold;
    }
}
.bar-color-top {
    height: 20px;
    background-color: $accent-color-1;
}
//overlay disable for form step 1
.booking-step1 {
    .disable:before {
        background-color: rgba(255, 255, 255, 0.83);
    }
}
.summary-content {
    background-color: #efefef;
    h3 {
        color: #000;
    }
    span {
        color:#000;
    }

}
label {
    color:#000;
}
.btn-primary {
    background-color: $accent-color-1;
    border-color: $accent-color-1;
}
.button-excell {
    background-color: #1f6d42;
}
svg {
    color: $accent-color-1
}
.summary-page {
    .data-information {
        background-color:#fff;
        h6 {
            span  {
                color: #000
            }
    
        }
    }
}
.table-bordered thead td, .table-bordered thead th {
    background-color: #fff;
}
Button {
    svg {
        color: #fff
    }
}